import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import { handleAsyncThunk } from '../../Utils/service';
import { StoreApiIndustry } from '../Interface/industry';

export const getAllIndustry = createAsyncThunk('getAllIndustry', async () =>
  handleAsyncThunk('/template/Industry', 'get', ''),
);
interface InitialState {
  isLoading: boolean;
  industryData: StoreApiIndustry | null;
  error: SerializedError;
}

export const industry = createSlice({
  name: 'industry',
  initialState: {
    isLoading: false,
    industryData: {},
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as InitialState,
  extraReducers: (builder) => {
    // getAllIndustry
    builder.addCase(getAllIndustry.pending, (state) => {
      state.isLoading = true;
      state.error.message = '';
    });
    builder.addCase(getAllIndustry.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.industryData = action.payload;
      } else {
        state.error.message = action?.payload?.response?.data?.message;
      }
    });
    builder.addCase(getAllIndustry.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  reducers: {
    clearState(state: InitialState) {
      state.industryData = null;
      state.isLoading = false;
      state.error = {
        name: '',
        message: '',
        stack: '',
        code: '',
      };
    },
  },
});

export const { clearState } = industry.actions;
