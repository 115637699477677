import { Backdrop, CircularProgress } from '@mui/material';

const Loader = (props: { loading: boolean }) => {
  const { loading } = props;
  return (
    <Backdrop
      sx={{ color: 'var(--white)', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
export default Loader;
