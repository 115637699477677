import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Navigation, Pagination } from 'swiper/modules';
import SliderImage1_1 from '../Assets/img/slider1_1.webp';
import SliderImage1_2 from '../Assets/img/slider1_2.webp';
import SliderImage2_1 from '../Assets/img/slider2_1.webp';
import SliderImage2_2 from '../Assets/img/slider2_2.webp';
import SliderImage3 from '../Assets/img/slider3.webp';
import SliderImage4 from '../Assets/img/slider4.webp';
import SliderImage5 from '../Assets/img/slider5.webp';

const ServiceSlider = () => (
  <Swiper
    spaceBetween={10}
    slidesPerView={1}
    navigation
    pagination={{
      clickable: true,
    }}
    mousewheel
    keyboard
    modules={[Navigation, Pagination]}
    autoplay
    loop
  >
    <SwiperSlide>
      <div className="d-flex justify-content-between">
        <div
          className="slider-image-wrap"
          style={{ width: '50%', paddingLeft: '40px', paddingRight: '8px' }}
        >
          <img
            src={SliderImage1_1}
            alt="slider1_1"
            className="slider-boxShadow"
          />
        </div>
        <div
          className="slider-image-wrap"
          style={{ width: '50%', paddingLeft: '8px', paddingRight: '40px' }}
        >
          <img
            src={SliderImage1_2}
            alt="slider1_2"
            className="slider-boxShadow"
          />
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="slider-image-wrap"
          style={{ paddingLeft: '40px', paddingRight: '40px' }}
        >
          <img src={SliderImage3} alt="slider3" />
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="slider-image-wrap"
          style={{ paddingLeft: '40px', paddingRight: '40px' }}
        >
          <img src={SliderImage5} alt="slider5" />
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="slider-image-wrap"
          style={{ paddingLeft: '40px', paddingRight: '40px' }}
        >
          <img src={SliderImage4} alt="slider4" />
        </div>
      </div>
    </SwiperSlide>
    <SwiperSlide>
      <div className="d-flex justify-content-between">
        <div
          className="slider-image-wrap"
          style={{ width: '50%', paddingLeft: '40px', paddingRight: '8px' }}
        >
          <img
            src={SliderImage2_1}
            alt="slider2_1"
            className="slider-boxShadow"
          />
        </div>
        <div
          className="slider-image-wrap"
          style={{ width: '50%', paddingLeft: '8px', paddingRight: '40px' }}
        >
          <img
            src={SliderImage2_2}
            alt="slider2_2"
            className="slider-boxShadow"
          />
        </div>
      </div>
    </SwiperSlide>
  </Swiper>
);

export default ServiceSlider;
