import { Button } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  clearState,
  getCurrentUser,
} from '../../Redux/Slice/authenticationSlice';
import { ROUTE } from '../../Enum';

const BackToLoginButton = ({ isAccountVerification = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBackButton = () => {
    navigate(`/${ROUTE.LOGIN}`);
    dispatch(clearState());
    if (isAccountVerification) {
      // @ts-expect-error Will fix this
      dispatch(getCurrentUser());
    }
  };

  window.onpopstate = () => {
    handleBackButton();
  };

  return (
    <>
      <Button
        variant="text"
        className="fw-semibold"
        startIcon={<KeyboardArrowLeftIcon />}
        sx={{
          '& .MuiButton-startIcon': {
            marginRight: '0px',
          },
        }}
        onClick={() => {
          navigate(`/${ROUTE.LOGIN}`);
          dispatch(clearState());
          if (isAccountVerification) {
            // @ts-expect-error Will fix this
            dispatch(getCurrentUser());
          }
        }}
      >
        Back to log in
      </Button>
    </>
  );
};

export default BackToLoginButton;
