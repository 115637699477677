import {
  ApiOrder,
  CompanyAccountOrderBy,
  ServiceAdvicerOrderBy,
  TemplateOrderBy,
  ToastMessageType,
  UserAccountOrderBy,
  UserAccountTabType,
} from '../../Enum';
import { UserTemplateData } from '../Interface';

const commonInitialState = {
  MANAGE_CATEGORY_MODAL: {
    isModalOpen: false,
    title: '',
    subTitle: '',
    type: '',
    modalType: '',
    props: {},
  },
  MANAGE_LEFTSIDEBAR: {
    isLeftSidebarOpen: false,
    isNavigateUnable: true,
  },
  TEMPLATE: {
    step: 1 || 2 || 3 || 4,
  },
  TOAST_MESSAGE: {
    isOpen: false,
    type: ToastMessageType.SUCCESS,
    message: '',
    description: '',
  },
  CATEGORY_LIST: [],
  TEMPLATE_STEP: { isVisible: false },
  MANAGE_TASKRULESIDEBAR: {
    isTaskRuleSidebarOpen: false,
    isTaskRuleEmptyState: false,
  },
  MEDIA_MODAL: {
    isOpen: false,
  },
  MANAGE_BACKBUTTON: {
    title: '',
    url: '',
  },
  PRICEBOOK_MODAL: {
    isModalOpen: false,
    title: '',
    modalType: '',
  },
};

export const userTemplateInitialState: UserTemplateData = {
  industryId: '',
  mainCategoryId: '',
  subCategoryId: '',
  mainCategoryName: '',
  subCategoryName: '',
  selectedManageCategoryId: [],
  deleteCategoryId: '',
  taskIndex: null,
  editTemplateDetail: null,
  isEditOrDeleted: false,
  propsForDeleteConfirmation: {
    mainCategoryTaskIndex: 0,
    subCategoryTaskIndex: 0,
  },
  isFooterNextBtnClicked: false,
  isEditCategorySaveBtnClicked: false,
  isSaveBtnClicked: false,
  templateOrder: ApiOrder.DESC,
  orderBy: TemplateOrderBy.CREATED_AT,
  page: 1,
  rowsPerPage: 10,

  companyOrder: ApiOrder.DESC,
  companyOrderBy: CompanyAccountOrderBy.CREATED_AT,
  companyPage: 1,
  companyRowsPerPage: 10,
  companySearchField: '',
  companySearchFieldLoader: false,
  userDetail: {
    firstName: '',
    lastName: '',
    email: '',
    role: '',
  },

  userAccountSearchFieldText: '',
  userAccountSearchFieldLoader: false,
  userAccountOrderBy: UserAccountOrderBy.EMAIL,
  userAccountOrder: ApiOrder.DESC,
  userAccountPage: 1,
  userAccountRowsPerPage: 10,

  superAdminTemplateOrder: ApiOrder.DESC,
  superAdminOrderBy: TemplateOrderBy.CREATED_AT,
  superAdminPage: 1,
  superAdminRowsPerPage: 10,
  callingApiAtInterval: false,

  realTemplateEditLoader: false,
  templateActionType: '',
  categoryPage: 1,
  searchFieldText: '',
  searchFieldLoader: false,

  // Service advicer
  serviceAdvicerOrder: ApiOrder.DESC,
  serviceAdvicerOrderBy: ServiceAdvicerOrderBy.UPDATED_AT,
  serviceAdvicerPage: 1,
  serviceAdvicerRowsPerPage: 10,
  serviceAdvicerSearchField: '',
  serviceAdvicerSearchFieldLoader: false,

  // Service advicer
  pendingServiceAdvicerOrder: ApiOrder.DESC,
  pendingServiceAdvicerOrderBy: ServiceAdvicerOrderBy.UPDATED_AT,
  pendingServiceAdvicerPage: 1,
  pendingServiceAdvicerRowsPerPage: 10,
  pendingServiceAdvicerSearchField: '',
  pendingServiceAdvicerSearchFieldLoader: false,

  mainCategoryAccordianState: [],
  subCategoryAccordianState: {},

  userAccountActiveTab: UserAccountTabType.ACTIVE,

  archivedTemplateRowsPerPage: 10,
  archivedTemplatePage: 1,
  archivedTemplateOrder: ApiOrder.DESC,
  archivedTemplateOrderBy: TemplateOrderBy.CREATED_AT,
};

export default commonInitialState;
