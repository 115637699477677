import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0c63ca', // This is an blue looking color
    },
    background: {
      default: 'var(--gray2)',
    },
  },
  typography: {
    fontFamily: 'var(--fontFamily)',
    allVariants: {
      color: 'var(--black)',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          lineHeight: 'initial',
          overflowX: 'hidden',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '100% !important',
          paddingLeft: '0 !important',
          paddingRight: '0 !important',
          '& .full-container-width': {
            maxWidth: '100%',
          },
          '& .box-container-width': {
            maxWidth: '911px',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 'initial',
          letterSpacing: 0,
          '@media (max-width:600px)': {
            fontSize: '14px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          lineHeight: 'initial',
          letterSpacing: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiSelect-select': {
            padding: '10.5px 16px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '600',
          borderRadius: '40px',
          padding: '9.5px 20px',
          textTransform: 'inherit',
          fontFamily: 'var(--fontFamily1)',
          fontSize: '18px',
          letterSpacing: 0,
          boxShadow: 'none',
          lineHeight: 'initial',
          '@media (max-width:900px)': {
            fontSize: '16px',
            padding: '8px 20px',
          },
          '@media (max-width:600px)': {
            fontSize: '14px',
            padding: '8px 10px',
          },
          '&:hover': {
            background: 'var(--border-color2)',
            boxShadow: 'none',
          },
          '&.MuiButton-textPrimary': {
            background: 'transparent',
            fontSize: 'var(--baseFont)',
            padding: '6px 8px',
            lineHeight: '100%',
            color: 'var(--blue)',
            '&:hover': {
              background: 'var(--blue)',
              color: 'var(--white)',
            },
            '& .MuiButton-startIcon': {
              marginRight: '5px',
              marginLeft: '0px',
            },
            '@media (max-width:600px)': {
              fontSize: '14px',
            },
          },
          '&.MuiButton-outlined': {
            background: 'var(--white)',
            borderColor: 'var(--blue)',
            color: 'var(--blue)',
            '&:hover': {
              background: 'var(--border-color2)',
              color: 'var(--white)',
              borderColor: 'var(--border-color2)',
              '@media (max-width:1024px)': {
                background: 'var(--white)',
                color: 'var(--blue)',
                borderColor: 'var(--blue)',
              },
            },
          },
          '&.MuiButton-contained': {
            background: 'var(--blue)',
            border: '1px solid var(--blue)',
            color: 'var(--white)',
            '&:hover': {
              background: 'var(--border-color2)',
              borderColor: 'var(--border-color2)',
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              color: 'var(--white)',
              opacity: '0.3',
            },
            '&.statusBtn': {
              fontWeight: '600',
              fontFamily: 'var(--fontFamily1)',
              fontSize: '14px',
              padding: '8.5px 5px',
              minWidth: '89px',
              height: '38px',
              lineHeight: '1',
              '@media (max-width:600px)': {
                fontSize: '14px',
                padding: '5.5px 5px',
                minWidth: '62px',
              },
            },
          },
          '&.denger-btn': {
            color: 'var(--errorColor)',
            '&:hover': {
              background: 'var(--errorColor)',
              color: 'var(--white)',
              borderColor: 'var(--errorColor)',
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: '4px',
          color: 'var(--blue)',
          height: '32px',
          width: '32px',
          '&:hover': {
            background: 'var(--blue)',
            color: 'var(--white)',
          },
          '@media (max-width:600px)': {
            height: '28px',
            width: '28px',
            padding: '2px',
          },
          '&.gray-icon-btn': {
            color: 'var(--gray1)',
            background: 'var(--white)',
            '&:hover': {
              background: 'var(--gray1)',
              color: 'var(--white)',
            },
          },
          '&.blue-icon-btn': {
            color: 'var(--blue)',
            background: 'var(--lightBlue)',
            '&:hover': {
              color: 'var(--white)',
              background: 'var(--blue)',
            },
          },
          '&.modal-close-btn': {
            color: 'var(--gray1)',
            '&:hover': {
              background: 'var(--gray1)',
              color: 'var(--white)',
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          '& .MuiInputBase-formControl': {
            borderRadius: '8px',
            background: 'var(--white)',
            '&.Mui-focused': {
              '& fieldset': {
                borderWidth: '1px',
                borderColor: 'var(--blue)',
                boxShadow:
                  '1px 2px 8px 0px rgba(21, 123, 243, 0.24), 1px 4px 12px 0px rgba(21, 123, 243, 0.15)',
              },
            },
            '& input': {
              padding: '9px 16px',
              fontSize: '18px',
              color: 'var(--black)',
              borderColor: 'var(--border-color)',
              '&::placeholder': {
                color: 'var(--border-color)',
                opacity: 1,
              },
              '@media (max-width:900px)': {
                fontSize: '16px',
              },
              '&.Mui-disabled': {
                background: 'var(--gray)',
              },
            },
            '& fieldset': {
              borderColor: 'var(--border-color)',
            },
            '& .gray-icon-btn': {
              marginRight: '-2px',
            },
          },
          '& .MuiRadio-root ': {
            padding: '6px 10px',
            fontSize: '18px',
          },
          '& .Mui-error': {
            color: 'var(--errorColor)',
            fontFamily: 'Avenir Next Demi',
            fontWeight: 600,
            fontSize: '14px',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '4px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '5px',
          marginRight: '5px',
        },
      },
    },
  },
});

export default theme;
