import { Box, Grid, Typography } from '@mui/material';
import TitleIcon from '../Assets/img/titleIcon.svg';
import ServiceIcon1 from '../Assets/img/serviceIcon1.svg';
import ServiceIcon2 from '../Assets/img/serviceIcon2.svg';
import ServiceIcon3 from '../Assets/img/serviceIcon3.svg';

const serviceData = [
  {
    image: `${ServiceIcon1}`,
    title: 'Consistently superior calls',
    description: `A detailed, totally customizable, procedural checklist provides
            unmatched guidance for every technician, allowing them to perform a
            superior technical job on every call.`,
  },
  {
    image: `${ServiceIcon2}`,
    title: 'Exclusive task assistance',
    description:
      'Includes full access to our proprietary troubleshooting and task assistance guides to ensure technicians provide a superior level of technical excellence.',
  },
  {
    image: `${ServiceIcon3}`,
    title: 'Full library of templates',
    description:
      'Leverage our extensive library of editable templates that can be tailored to your specific company.   Save 100’s of hours by starting with a foundation as opposed to starting from scratch.',
  },
];

const ServiceComponent = () => (
  <Box className="m-auto color-white container-padding" maxWidth={1146}>
    <Box
      className="section-title d-flex align-items-start align-items-start justify-content-center"
      mb={3}
    >
      <img src={TitleIcon} alt="title-icon" />
      <Typography className="fs-40 fw-bold color-white" ml={2}>
        Transform your service calls
      </Typography>
    </Box>

    <Box className="text-center" mt={6}>
      <Grid
        container
        spacing={{ md: 7, sm: 3, xs: 2 }}
        className="justify-content-center"
      >
        {serviceData.map((service: any) => (
          <Grid item xs={12} sm={6} md={4} key={service.title}>
            <Box className="service-icon d-flex align-items-center justify-content-center m-auto rounded-100">
              <img src={service.image} alt={service.image} />
            </Box>
            <Typography className="color-white fs-24 fw-semibold mb-1">
              {service.title}
            </Typography>
            <Typography className="color-white pera-text fs-18">
              {service.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  </Box>
);

export default ServiceComponent;
