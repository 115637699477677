const textConfiguration = {
  MANAGE_CATEGORY_MODAL: {
    addMainCategoryTitle: 'Add category',
    addSubCategoryTitle: 'Add subcategories',
    addTaskTItle: 'New category tasks',

    addMainCategorySubTitle: '',
    addSubCategorySubTitle: '',
    addTaskSubTItle: '',

    deleteMainCategorySubTitle:
      'The category will be permanently deleted from Template including its sub-categories & tasks',
    deleteSubCategorySubTitle:
      'The subcategory will be permanently deleted from Template including its tasks',
    deleteTaskSubTitle: '',

    renameMainCategoryTitle: 'Rename category',
    renameMainCategorySubTitle: '',
    renameSubCategorySubTitle: '',

    newTemplateTitle: 'New template',
    renameTemplateTitle: 'Rename template',
    cloneTemplateTitle: 'Clone ',
    cloneTemplateSubTitle:
      "A template will be copied and saved to the <span><b>'My templates'</b></span>.",

    deleteChecklistItemTitle: 'Delete checklist item?',
    deleteChecklistItemSubTitle:
      'The checklist item will be permanently deleted from Template creation',

    deleteChecklistTaskTitle: 'Delete ',
    deleteChecklistTaskSubTitle:
      'The checklist item will be permanently deleted from Template',

    deleteChecklistSubCategoryTitle: 'Delete ',
    deleteChecklistSubCategorySubTitle:
      'The checklist item will be permanently deleted from Template including its tasks',

    deleteChecklistMainCategoryTitle: 'Delete ',
    deleteChecklistMainCategorySubTitle:
      'The checklist item will be permanently deleted from Template including its sub-categories & tasks',

    editTemplateTitle: 'Edit template',
    editTemplateSubTitle:
      "To edit a [Template Name] from the template library, we’ll make a copy and add it to <span><b>'My templates'</b></span>.",

    imageShowModal: 'Show task requirements',
    showCommentTitle: 'Show task comments',
    moreInfo: 'Show task info',
  },
  TOAST_MESSAGE: {
    superAdminAddedTitle: 'Super admin added successfully',
    superAdminDeletedTitle: 'Super admin deleted successfully',
    superAdminUpdatedTitle: 'Super admin updated successfully',
    superAdminSettingUpdatedTitle: 'Super admin setting updated successfully',

    adminSettingUpdatedTitle: 'Admin setting updated successfully',

    categoryDeletedTitle: 'Category deleted successfully',
    categoryAddedTitle: 'Category added successfully',

    subCategoryAddedTitle: 'Subcategory added successfully',

    templateDeletedTitle: 'Template deleted successfully',
    templateUpdatedTitle: 'Template updated successfully',
    templateCreatedTitle: 'Template created',
    templateClonedTitle: 'Template cloned!',
    templateReamedTitle: 'Template renamed successfully',

    userAddedTitle: 'User added successfully',
    bulkUserAddedTitle: 'New users added successfully',
    userUpdatedTitle: 'User updated successfully',
    userDeletedTitle: 'User deleted successfully',

    adminAddedTitle: 'Admin added successfully',
    adminUpdatedTitle: 'Admin updated successfully',
    adminDeletedTitle: 'Admin deleted successfully',

    companyAddedTitle: 'Company added successfully',
    companyUpdatedTitle: 'Company updated successfully',

    jobReviwedTitle: 'Job successfully reviewed',
    jobReviwedSubTitle: 'Technician will be notified.',

    serviceProviderSettingUpdatedTitle:
      'Service advisor setting updated successfully',

    supportMailSentTitle: 'Support message send successfully',

    restoreTemplateTitle: 'Template restored succuessfully',

    userActivatedSuccessfully: 'User activated successfully',
    userDeactivatedSuccessfully: 'User deactivated successfully',

    PricePackageDeletedTitle: 'Package deleted successfully',
  },
  COMMON_MAIL: {
    supportMail: 'support@homeservicechecklist.com',
  },
};

export default textConfiguration;
