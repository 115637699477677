import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import textConfiguration from '../../Utils/textConfig';

const Footer = () => {
  const { supportMail } = textConfiguration.COMMON_MAIL;
  return (
    <Box className="footer text-center" my={3.5}>
      <Typography variant="body1" mb={0.625}>
        Troubles logging in?
      </Typography>
      <Typography variant="body1">
        Email at
        <Link
          to={`mailto:${encodeURIComponent(supportMail)}`}
          className="fw-medium color-blue text-decoration-underline"
          style={{ marginLeft: '4px' }}
        >
          {supportMail}
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
