import { Box, Typography } from '@mui/material';

const Footer = () => (
  <Box pt={{ md: '34px', xs: 2 }} py={{ md: '32px', xs: 2 }}>
    <Typography className="fs-16 text-center">
      © {new Date().getFullYear()}. All Rights Reserved
    </Typography>
  </Box>
);

export default Footer;
