import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import AboutUs from './aboutUs';
import ContactUs from './contactUs';
import Faq from './faq';
import FeaturesComponent from './featuresComponent';
import Footer from './footer';
import Header from './header';
import JobComponent from './jobComponent';
// import Pricing from './pricing';
import ServiceComponent from './serviceComponent';
import { LocalStorage } from '../Enum';
import { StoreState } from '../Redux/Interface';
import { loadRecaptcha, userRoleRoute } from '../Utils/service';

const LandingPage = () => {
  const { isLoginPageVisible, isUserDataLoading, userData } = useSelector(
    (state: StoreState) => state.user,
  );

  loadRecaptcha();

  useEffect(() => {
    if (
      localStorage.getItem(LocalStorage.ACCESS_TOKEN) != null ||
      localStorage.getItem(LocalStorage.REFRESH_TOKEN) != null
    ) {
      if (!isUserDataLoading && !isLoginPageVisible && userData?.response) {
        window.open(`${userRoleRoute(userData?.response?.role)}`, '_self');
      }
    }
  }, [isLoginPageVisible, isUserDataLoading, userData]);

  return (
    <Box className="landing-page">
      <Header />
      <Box className="main-content">
        <Box className="bg-gray">
          <JobComponent />
        </Box>
        <Box
          id="serviceSection"
          sx={{ background: '#033A6D' }}
          py={{ sm: 7.5, xs: 5 }}
        >
          <ServiceComponent />
        </Box>
        <Box className="goToId" id="featureSection">
          <FeaturesComponent />
        </Box>
        {/* <Box
        id="pricingSection"
        sx={{ background: 'var(--lightBlue)' }}
        pt={7}
        pb={8.5}
      >
        <Pricing />
      </Box> */}
        <Box className="goToId" id="faqSection" />
        <Box className="faq-section" sx={{ background: '#033A6D' }}>
          <Faq />
        </Box>
        <Box className="goToId" id="aboutUsSection">
          <AboutUs />
        </Box>
        <Box className="goToId" id="contactUsSection" />
        <Box className="bg-gray" pt={{ sm: 8, xs: 4 }} pb={{ md: 10, xs: 5 }}>
          <ContactUs />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default LandingPage;
