import { useEffect, useLayoutEffect } from 'react';
import './Assets/css/App.css';
import 'react-toastify/dist/ReactToastify.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import theme from './Theme/theme';
import { CommonStateType, LocalStorage, ROUTE } from './Enum';
import {
  getCurrentUser,
  getPublicKey,
} from './Redux/Slice/authenticationSlice';
import { printLogs } from './Utils/service';
import { commonState } from './Redux/Slice/commonSlice';
import LandingPage from './LandingPage/landingPage';
import AuthenticationPanal from './Component/Authentication/authenticationPanal';
import PageNotFound from './Component/pageNotFound';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const cdnUrl = process.env.REACT_APP_CDN_URL;

    const fontFaceStyles = `
         @font-face {
      font-family: 'Avenir Next';
      src: url('${cdnUrl}/AvenirNext-Regular.eot');
      src:
        url('${cdnUrl}/AvenirNext-Regular.eot?#iefix')
          format('embedded-opentype'),
        url('${cdnUrl}/AvenirNext-Regular.woff2')
          format('woff2'),
        url('${cdnUrl}/AvenirNext-Regular.woff')
          format('woff'),
        url('${cdnUrl}/AvenirNext-Regular.ttf')
          format('truetype'),
        url('${cdnUrl}/AvenirNext-Regular.svg#AvenirNext-Regular')
          format('svg');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Avenir Next Demi';
      src: url('${cdnUrl}/AvenirNext-DemiBold.eot');
      src:
        url('${cdnUrl}/AvenirNext-DemiBold.eot?#iefix')
          format('embedded-opentype'),
        url('${cdnUrl}/AvenirNext-DemiBold.woff2')
          format('woff2'),
        url('${cdnUrl}/AvenirNext-DemiBold.woff')
          format('woff'),
        url('${cdnUrl}/AvenirNext-DemiBold.ttf')
          format('truetype'),
        url('${cdnUrl}/AvenirNext-DemiBold.svg#AvenirNext-DemiBold')
          format('svg');
      font-weight: 600;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Avenir Next';
      src: url('${cdnUrl}/AvenirNext-Heavy.eot');
      src:
        url('${cdnUrl}/AvenirNext-Heavy.eot?#iefix')
          format('embedded-opentype'),
        url('${cdnUrl}/AvenirNext-Heavy.woff2')
          format('woff2'),
        url('${cdnUrl}/AvenirNext-Heavy.woff')
          format('woff'),
        url('${cdnUrl}/AvenirNext-Heavy.ttf')
          format('truetype'),
        url('${cdnUrl}/AvenirNext-Heavy.svg#AvenirNext-Heavy')
          format('svg');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Avenir Next';
      src: url('${cdnUrl}/AvenirNext-Medium.eot');
      src:
        url('${cdnUrl}/AvenirNext-Medium.eot?#iefix')
          format('embedded-opentype'),
        url('${cdnUrl}/AvenirNext-Medium.woff2')
          format('woff2'),
        url('${cdnUrl}/AvenirNext-Medium.woff')
          format('woff'),
        url('${cdnUrl}/AvenirNext-Medium.ttf')
          format('truetype'),
        url('${cdnUrl}/AvenirNext-Medium.svg#AvenirNext-Medium')
          format('svg');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Avenir Next';
      src: url('${cdnUrl}/AvenirNext-Bold.eot');
      src:
        url('${cdnUrl}/AvenirNext-Bold.eot?#iefix')
          format('embedded-opentype'),
        url('${cdnUrl}/AvenirNext-Bold.woff2')
          format('woff2'),
        url('${cdnUrl}/AvenirNext-Bold.woff')
          format('woff'),
        url('${cdnUrl}/AvenirNext-Bold.ttf')
          format('truetype'),
        url('${cdnUrl}/AvenirNext-Bold.svg#AvenirNext-Bold')
          format('svg');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }
        `;

    const style = document.createElement('style');
    style.appendChild(document.createTextNode(fontFaceStyles));
    document.head.appendChild(style);

    if (cdnUrl) {
      document
        .getElementById('favicon')
        .setAttribute('href', `${cdnUrl}/favicon.ico`);
      document
        .getElementById('apple-touch-icon')
        .setAttribute('href', `${cdnUrl}/logo192.png`);
      document
        .getElementById('manifest')
        .setAttribute('href', `${cdnUrl}/manifest.json`);
    }
  }, []);

  useEffect(() => {
    // @ts-expect-error Will fix this
    dispatch(getCurrentUser());
    // @ts-expect-error Will fix this
    dispatch(getPublicKey());
  }, []);

  useEffect(() => {
    window.addEventListener('storage', (e) => {
      printLogs('key', 'changed');
      if (e.key === LocalStorage.ACCESS_TOKEN) {
        window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        // User is inactive on the current tab
        dispatch(
          commonState({
            type: CommonStateType.TOAST_MESSAGE,
            data: {
              isOpen: false,
              message: '',
              type: '',
              description: '',
            },
          }),
        );
      }
    };

    // Add event listener for visibilitychange
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
  };

  useEffect(() => {
    document.addEventListener('gesturestart', (e) => {
      e.preventDefault();
    });

    document.addEventListener('gesturechange', (e) => {
      e.preventDefault();
    });

    document.addEventListener('gestureend', (e) => {
      e.preventDefault();
    });
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <Wrapper>
            {/* {(userData?.response?.role === UserRole.SUPER_ADMIN ||
            userData?.response?.role === UserRole.MASTER_ADMIN) && ( */}
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/" element={<AuthenticationPanal component="/" />} />
              <Route
                path={`/${ROUTE.LOGIN}`}
                element={<AuthenticationPanal component={ROUTE.LOGIN} />}
              />
              <Route
                path={`/${ROUTE.ACCOUNT_VERIFICATION}`}
                element={
                  <AuthenticationPanal component={ROUTE.ACCOUNT_VERIFICATION} />
                }
              />
              <Route
                path={`/${ROUTE.RESET_PASSWORD_VERIFICATION}`}
                element={
                  <AuthenticationPanal
                    component={ROUTE.RESET_PASSWORD_VERIFICATION}
                  />
                }
              />
              <Route
                path={`/${ROUTE.FORGOT_PASSWORD}`}
                element={
                  <AuthenticationPanal component={ROUTE.FORGOT_PASSWORD} />
                }
              />
              <Route
                path={`/${ROUTE.FORGOT_USERNAME}`}
                element={
                  <AuthenticationPanal component={ROUTE.FORGOT_USERNAME} />
                }
              />
              <Route
                path={`/${ROUTE.RESET_PASSWORD}`}
                element={
                  <AuthenticationPanal component={ROUTE.RESET_PASSWORD} />
                }
              />
              <Route
                path={`/${ROUTE.INVITE_USER}`}
                element={<AuthenticationPanal component={ROUTE.INVITE_USER} />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Wrapper>
        </BrowserRouter>
      </ThemeProvider>
      <ToastContainer />
    </>
  );
};

export default App;
