import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const HomeServiceSlider = () => (
  <Swiper
    spaceBetween={10}
    slidesPerView={1}
    pagination={{
      clickable: true,
    }}
    mousewheel
    keyboard
    modules={[Pagination]}
    autoplay
    loop
  >
    <SwiperSlide>
      <ul className="home-service-items fs-18 pera-text color-black1">
        <li>
          <strong>Simple & User-friendly</strong> tool that will have your team
          up and running in no time
        </li>
        <li>
          <strong>Mobile Accessibility:</strong> A mobile-friendly design that
          allows technicians to access the features on-the-go.
        </li>
        <li>
          <strong>Customizable Templates:</strong> Allow users to create &
          customize checklist templates tailored to their specific needs.
        </li>
        <li>
          <strong>Categories and Subcategories:</strong> Allow users to organize
          tasks into categories and subcategories for better management.
        </li>
        <li>
          <strong>Master Checklist Templates Library :</strong> Access to
          industry specific Master Templates (Plumbing, HVAC, Electric…)
        </li>
        <li>
          <strong>Service History and Records:</strong> Access to historical
          data of previous jobs, including service details.
        </li>
      </ul>
    </SwiperSlide>

    <SwiperSlide>
      <ul className="home-service-items fs-18 pera-text color-black1">
        <li>
          <strong>Job Specific Checklists:</strong> Detailed checklists for each
          job, outlining tasks that need to be completed making the evaluation
          process standard for your technicians
        </li>

        <li>
          <strong>Real-Time Updates: </strong> Allow a technician to update job
          status, report progress, and notify a back-office advisor in
          real-time.
        </li>
        <li>
          <strong>Offline Access:</strong> Allow a technician to access and
          update job information even without an internet connection, with
          synchronization once online.
        </li>
        <li>
          <strong>Customer Approval:</strong> Option to collect customer
          acceptance and signatures upon job completion for service
          verification.
        </li>
        <li>
          <strong>Backoffice Support:</strong> Allow senior service advisors to
          provide recommendations & next steps to the on-job technicians in
          real-time
        </li>
      </ul>
    </SwiperSlide>
    <SwiperSlide>
      <ul className="home-service-items fs-18 pera-text color-black1">
        <li>
          <strong>Customized Pricebook: </strong> Simple, customized pricebook
          to suggest various package options to the customer giving them an
          option to Accept/Decline each package
        </li>
        <li>
          <strong>AI Generated Tech Summary:</strong> Based on the technician
          task evaluation & comments
        </li>
        <li>
          <strong>Knowledge Base:</strong> Admins can upload support documents
          and assets to assist their technicians
        </li>
        <li>
          <strong>Job Summary PDF: </strong> Job Summary PDFs can be generated,
          saved, and emailed
        </li>
      </ul>
    </SwiperSlide>
  </Swiper>
);

export default HomeServiceSlider;
