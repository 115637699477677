const LoginUser = () => (
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 16C0 12.6337 2.68629 9.90476 6 9.90476C9.31373 9.90476 12 12.6337 12 16H0ZM6 9.14286C3.51375 9.14286 1.5 7.09714 1.5 4.57143C1.5 2.04571 3.51375 0 6 0C8.48625 0 10.5 2.04571 10.5 4.57143C10.5 7.09714 8.48625 9.14286 6 9.14286Z"
      fill="#FAFAFA"
    />
  </svg>
);

export default LoginUser;
