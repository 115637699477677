import { Typography } from '@mui/material';

const TextFieldError = ({ error }) => (
  <Typography
    variant="body1"
    className="fs-14 fw-semibold text-danger text-error"
    // sx={{ marginLeft: '5px' }}
  >
    {error}
  </Typography>
);

export default TextFieldError;
