import {
  createSlice,
  createAsyncThunk,
  SerializedError,
} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Config from '../../Utils/config';
import { encryptData } from '../../Utils/service';
import { LocalStorage } from '../../Enum';
import { StoreApiCurrentUser } from '../Interface/authentication';

export const userLogin = createAsyncThunk(
  'loginUser',
  async (data: { email: string; password: string; rememberMe: boolean }) => {
    try {
      return {
        status: 200,
        response: await Config(
          '/auth/login',
          'post',
          { email: data.email, password: data.password },
          false,
        ),
        requestData: data,
      };
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      if (!error?.response) {
        toast.error(error?.message);
      }
      return error;
    }
  },
);

export const forgotPassword = createAsyncThunk(
  'forgotPassword',
  async (data: { email: string; recaptchaToken: string }) => {
    try {
      return {
        status: 200,
        response: await Config('/auth/forgot-password', 'post', data),
      };
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      if (!error?.response) {
        toast.error(error?.message);
      }
      return error;
    }
  },
);

export const resetPassword = createAsyncThunk(
  'resetPassword',
  async (data: { password: string; resetToken: string }) => {
    try {
      return {
        status: 200,
        response: await Config('/auth/reset-password', 'post', data),
      };
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      if (!error?.response) {
        toast.error(error?.message);
      }
      return error;
    }
  },
);

export const acceptInvitationApi = createAsyncThunk(
  'acceptInvitationApi',
  async (data: { password: string; inviteCode: string }) => {
    try {
      return {
        status: 200,
        response: await Config('/auth/accept-invitation', 'post', data),
      };
    } catch (error: any) {
      toast.error(error?.response?.data?.message);
      if (!error?.response) {
        toast.error(error?.message);
      }
      return error;
    }
  },
);

export const getCurrentUser = createAsyncThunk('getUser', async () => {
  try {
    return {
      status: 200,
      response: await Config('/auth/current-user', 'get', ''),
    };
  } catch (error: any) {
    return error;
  }
});

export const getPublicKey = createAsyncThunk('getPublicKey', async () => {
  try {
    return {
      status: 200,
      response: await Config('/auth/crypto/public-key', 'get', '', false),
    };
  } catch (error: any) {
    return error;
  }
});

export const authenticationSlice = createSlice({
  name: 'authenticattion',
  initialState: {
    isLoading: false,
    data: null,
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as {
    isLoading: boolean;
    data: any;
    error: SerializedError;
  },
  extraReducers: (builder) => {
    // login
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.data = action.payload;
        localStorage.setItem(
          LocalStorage.ACCESS_TOKEN,
          encryptData(action.payload?.response?.accessToken),
        );
        localStorage.setItem(
          LocalStorage.REFRESH_TOKEN,
          encryptData(action.payload?.response?.refreshToken),
        );
        if (action.payload.requestData.rememberMe) {
          localStorage.setItem(
            LocalStorage.EMAIL,
            encryptData(action.payload.requestData.email),
          );
        } else {
          localStorage.removeItem(LocalStorage.EMAIL);
        }
      } else {
        state.error.message = action.payload?.response?.data?.message;
      }
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // forgot-password
    builder.addCase(forgotPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.data = action.payload;
      } else {
        state.error.message = action?.payload?.response?.data?.message;
      }
    });
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
    // reset-password
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        toast.success('Password changed successfully');
        state.data = action.payload;
      } else {
        state.error.message = action?.payload?.response?.data?.message;
      }
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  reducers: {
    clearState(state: any) {
      state.data = null;
      state.isLoading = false;
      state.error = {
        name: '',
        message: '',
        stack: '',
        code: '',
      };
    },
  },
});

export const getUser = createSlice({
  name: 'getUser',
  initialState: {
    isUserDataLoading: false,
    isLoginPageVisible: false,
    userData: {},
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as {
    isUserDataLoading: boolean;
    isLoginPageVisible: boolean;
    userData: StoreApiCurrentUser | null;
    error: SerializedError;
  },
  extraReducers: (builder) => {
    // getCurrentUser
    builder.addCase(getCurrentUser.pending, (state) => {
      state.isUserDataLoading = true;
      state.isLoginPageVisible = false;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.isUserDataLoading = false;
      if (action.payload.status === 200) {
        state.userData = action.payload;
        if (!action.payload.response.isVerified) {
          state.isLoginPageVisible = true;
        } else {
          state.isLoginPageVisible = false;
        }
      } else {
        state.error.message = action.payload.response?.data?.message;
        state.error.code = String(action?.payload?.response?.status);
        state.userData = null;
        state.isLoginPageVisible = true;
      }
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.isUserDataLoading = false;
      state.error = action.error;
      state.userData = null;
    });
  },
  reducers: {},
});

export const publicKey = createSlice({
  name: 'publicKey',
  initialState: {
    key: '',
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as {
    key: string;
    error: SerializedError;
  },
  extraReducers: (builder) => {
    // getCurrentUser
    builder.addCase(getPublicKey.fulfilled, (state, action) => {
      if (action.payload.status === 200) {
        state.key = action.payload.response;
      } else {
        state.error.message = action.payload.response?.data?.message;
        state.error.code = String(action?.payload?.response?.status);
      }
    });
    builder.addCase(getPublicKey.rejected, (state, action) => {
      state.error = action.error;
    });
  },
  reducers: {},
});

export const acceptInvitation = createSlice({
  name: 'acceptInvitation',
  initialState: {
    isLoading: false,
    data: {},
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as {
    isLoading: boolean;
    data: any;
    error: SerializedError;
  },
  extraReducers: (builder) => {
    // getCurrentUser
    builder.addCase(acceptInvitationApi.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(acceptInvitationApi.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.data = action.payload;
      } else {
        state.error.message = action.payload.response?.data?.message;
        state.error.code = String(action?.payload?.response?.status);
        state.data = null;
      }
    });
    builder.addCase(acceptInvitationApi.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.data = null;
    });
  },
  reducers: {
    clearAcceptInvitaionState(state: any) {
      state.data = null;
      state.isLoading = false;
      state.error = {
        name: '',
        message: '',
        stack: '',
        code: '',
      };
    },
  },
});

export const { clearState } = authenticationSlice.actions;
export const { clearAcceptInvitaionState } = acceptInvitation.actions;
