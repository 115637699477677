import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
import LoginPage from './loginPage';
import ForgotPassword from './forgotPassword';
import { ROUTE } from '../../Enum';
import ResetPassword from './resetPassword';
import AccountVerificationPage from './accountVerificationPage';
import ResetPasswordVerification from './resetPasswordVerificationPage';
import ErrorBoundary from '../CommonComponent/errorBoundry';
import { loadRecaptcha, removeRecaptcha } from '../../Utils/service';
import AcceptInvitation from './acceptInvitation';

const AuthenticationPanal = (props: { component: string }) => {
  const navigator = useNavigate();
  const { component } = props;
  // const navigate = useNavigate();
  let formComponent;
  if (component === `${ROUTE.FORGOT_PASSWORD}`) {
    formComponent = <ForgotPassword />;
  } else if (component === `${ROUTE.ACCOUNT_VERIFICATION}`) {
    formComponent = <AccountVerificationPage />;
  } else if (component === `${ROUTE.RESET_PASSWORD_VERIFICATION}`) {
    formComponent = <ResetPasswordVerification />;
  } else if (component === `${ROUTE.RESET_PASSWORD}`) {
    formComponent = <ResetPassword />;
  } else if (component === `${ROUTE.LOGIN}`) {
    formComponent = <LoginPage />;
  } else if (component === `${ROUTE.INVITE_USER}`) {
    formComponent = <AcceptInvitation />;
  } else {
    formComponent = <LoginPage />;
  }

  if (component === ROUTE.FORGOT_PASSWORD) {
    loadRecaptcha();
  } else {
    removeRecaptcha();
  }

  window.onpopstate = () => {
    navigator('/');
  };

  return (
    <Box className="account-pages" px={2}>
      <ErrorBoundary>{formComponent}</ErrorBoundary>
    </Box>
  );
};

export default AuthenticationPanal;
