import { Box, Typography } from '@mui/material';
import TitleIcon from '../Assets/img/titleIcon.svg';

const Faq = () => (
  <Box
    className="m-auto goToId container-padding"
    maxWidth={1284}
    py={{ sm: 7, xs: 5 }}
  >
    <Box
      className="section-title d-flex align-items-start align-items-start justify-content-center"
      mb={5.25}
    >
      <img src={TitleIcon} alt="title-icon" />
      <Typography className="fs-40 fw-bold color-white color-black1" ml={2}>
        Frequently Asked Questions
      </Typography>
    </Box>
    <Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          How much does this cost?
        </Typography>
        <Typography className="color-white fs-18">
          Currently Home Service Checklist is in pilot mode. We are evaluating
          the product and seeking customer feedback to improve it further.
          Pricing will be launched In 2025.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          How long will it take to setup?
        </Typography>
        <Typography className="color-white fs-18">
          Our onboarding team will work closely with you to get everything setup
          and working smoothly. This can typically be done in a few hours
          depending on the amount of customization you prefer.
        </Typography>
      </Box>
      <Box mb={7}>
        <Typography className="color-white fs-28 fw-semibold">
          Will this integrate with my CRM?
        </Typography>
        <Typography className="color-white fs-18">
          CRM integrations are on the product roadmap and planned throughout
          late 2024 and 2025.
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default Faq;
