const RadioSelected = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24ZM12 21.6C17.302 21.6 21.6 17.302 21.6 12C21.6 6.69806 17.302 2.4 12 2.4C6.69806 2.4 2.4 6.69806 2.4 12C2.4 17.302 6.69806 21.6 12 21.6ZM12 18C8.6863 18 6 15.3137 6 12C6 8.6863 8.6863 6 12 6C15.3137 6 18 8.6863 18 12C18 15.3137 15.3137 18 12 18Z"
      fill="#0C63CA"
    />
  </svg>
);

export default RadioSelected;
