import axios from 'axios';
import { LocalStorage } from '../Enum';
import { decryptData, getToken } from './service';

const Config = async (
  url: string,
  method: 'get' | 'post' | 'patch' | 'delete' | 'put',
  data = {},
  useAccessToken = true,
  companyId?: string,
) => {
  const BASE_URL = `${process.env.REACT_APP_URL}/api`;
  const response = await axios({
    url: BASE_URL + url,
    method,
    data,
    headers: {
      'x-company-id': companyId || undefined,
      'Api-version': 0,
      Authorization:
        (decryptData(localStorage.getItem(LocalStorage.ACCESS_TOKEN)) ||
          decryptData(localStorage.getItem(LocalStorage.REFRESH_TOKEN))) &&
        useAccessToken
          ? `Bearer ${getToken()}`
          : undefined,
    },
    responseType: 'json',
  });
  return response.data;
};

export default Config;
