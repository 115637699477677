import { Box, ListItem, ListItemButton, ListItemText } from '@mui/material';

const HeaderMenu = (props: { setIsMenuOpen: any }) => {
  const { setIsMenuOpen } = props;
  const itemList = [
    { name: 'Features', target: 'featureSection' },
    // { name: 'Pricing', target: 'pricingSection' },
    { name: 'FAQs', target: 'faqSection' },
    { name: 'About Us', target: 'aboutUsSection' },
    { name: 'Interest List', target: 'contactUsSection' },
  ];
  const handleMenuLinkClick = (targetId: string) => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  return (
    <Box className="d-block d-md-flex header-menu" component="ul" m={0} p={0}>
      {itemList.map((item) => (
        <ListItem
          className="d-flex"
          sx={{ p: { lg: '0px 19px', xs: '0 10px' } }}
          key={item.name}
        >
          <ListItemButton
            className="bg-transparent"
            sx={{
              padding: 0,
              '&.Mui-focusVisible': { textDecoration: 'underline' },
            }}
            onClick={() => handleMenuLinkClick(item.target)}
            tabIndex={0}
          >
            <ListItemText
              className="fs-18 space-nowrap "
              sx={{
                m: 0,
                '& .MuiTypography-root': {
                  fontSize: '18px',
                  '&:hover': { color: 'var(--blue)' },
                },
              }}
              primary={item.name}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </Box>
  );
};

export default HeaderMenu;
