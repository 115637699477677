import { Box, Grid, Typography } from '@mui/material';
import AboutImage from '../Assets/img/aboutImg.svg';
import TitleIcon from '../Assets/img/titleIcon.svg';

const AboutUs = () => (
  <Box className="landing-page-container">
    <Grid container className="align-items-center">
      <Grid
        item
        xs={12}
        lg={6}
        className="d-flex justify-content-center justify-lg-content-end"
        pr={{ lg: 6.5, xs: 1.875 }}
        pl={1.875}
      >
        <Box
          maxWidth={{ lg: 585, sm: '710px', xs: '100%' }}
          padding={{ lg: '12px 0px 0px', xs: '40px 0px 25px' }}
        >
          <Box className="section-title d-flex align-items-start" mb={4.25}>
            <img src={TitleIcon} alt="title-icon" />
            <Typography className="fs-40 fw-bold color-black1" ml={2}>
              About Us
            </Typography>
          </Box>
          <Typography className="fs-18 pera-text color-black1">
            Home Service Checklist was developed and created as a joint venture
            between Joe Cunningham and Tommy Mello.
          </Typography>
          <Typography className="fs-18 pera-text color-black1">
            Joe is an industry icon and a veteran of over 30 years, as well as
            being a member of the HVAC Hall of Fame. Tommy is the founder of the
            legendary A1 Garage Door Company, the first-ever garage door company
            to reach $200 million in annual revenue.
          </Typography>
          <Typography className="fs-18 pera-text color-black1">
            This perfectly matched pair of entrepreneurs have joined forces and
            created a tool that can successfully guide even the greenest of
            technicians to perform a stellar job on every call. Home Service
            Checklist provides an incredible customer experience, drives
            profitability, reduces callbacks and provides an endless source of
            customer-generated leads.
          </Typography>
          {/* <Typography className="fs-18 pera-text color-black1">
            This perfectly matched pair of entrepreneurs have joined forces and
            created the world’s first app that can successfully guide even the
            greenest of technicians to perform a stellar job on every call. This
            unlocks an incredible customer experience, high profitability,
            reduces callbacks to nearly zero and provides an endless source of
            customer-generated leads.
          </Typography> */}
          <Typography className="fs-18 pera-text color-black1">
            Contact us for a product demonstration - you’ll be glad you did!
          </Typography>
        </Box>
      </Grid>
      <Grid className="about-image-wrap position-relative" item xs={12} lg={6}>
        <Box
          className="position-relative m-auto"
          maxWidth={{ sm: '710px', xs: '100%' }}
        >
          <Typography className="developer-1 fs-28 color-white fw-semibold ">
            Tommy Mello
          </Typography>
          <img
            src={AboutImage}
            alt="Plumber Img"
            className="about-image m-auto ml-lg-0"
          />
          <Typography className="developer-2 fs-28 color-white fw-semibold">
            Joe Cunningham
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </Box>
);

export default AboutUs;
