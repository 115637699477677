import { Box, Button, Drawer, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Logo from '../Assets/img/logo.svg';
import LoginUser from '../Assets/img/loginUser';
import { ROUTE } from '../Enum';
import HeaderMenu from './HeaderMenu';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  let timeout;
  useEffect(() => {
    window.onscroll = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => {
        if (
          document.body.scrollTop > 120 ||
          document.documentElement.scrollTop > 120
        ) {
          document.getElementById('header')?.classList.add('headerSticky');
        } else {
          document.getElementById('header')?.classList.remove('headerSticky');
        }
      }, 10);
    };
  }, []);

  return (
    <Box id="header" className="header bg-white rounded-0 boxShadow3">
      <Box className="d-flex justify-content-between align-items-center landing-page-container container-padding">
        <Box
          className="header-logo-img"
          sx={{ cursor: 'pointer' }}
          onClick={() => window.scrollTo(0, 0)}
        >
          <img src={Logo} alt="logo" />
        </Box>
        <Box className="d-flex align-items-center">
          <Box className="d-none d-md-block">
            <HeaderMenu setIsMenuOpen={setIsMenuOpen} />
          </Box>

          <IconButton
            className="d-block d-md-none bg-transparent color-blue"
            onClick={() => setIsMenuOpen(true)}
            sx={{
              padding: '0px !important',
              borderRadius: '0px',
              '& svg': {
                fontSize: '32px',
                ' & path': { fill: 'var(--blue) !important' },
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            className="right headerMenuDrawer"
            anchor="left"
            open={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
          >
            <IconButton
              className="menuCloseBtn"
              onClick={() => setIsMenuOpen(false)}
            >
              <CloseIcon />
            </IconButton>
            <HeaderMenu setIsMenuOpen={setIsMenuOpen} />
          </Drawer>
          <Button
            variant="contained"
            startIcon={<LoginUser />}
            onClick={() => {
              navigate(`${ROUTE.LOGIN}`);
            }}
            sx={{
              padding: { sm: '7px 25px', xs: '7px 25px' },
              marginLeft: '16px',
              marginRight: '1px',
            }}
            className="fs-16"
          >
            Log in
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
