import { Box, Typography } from '@mui/material';

function PageNotFound() {
  return (
    <Box className="text-center">
      <Typography variant="h1">404</Typography>
      <Typography variant="body1" className="">
        Page Not Found
      </Typography>
    </Box>
  );
}

export default PageNotFound;
