import { configureStore } from '@reduxjs/toolkit';
import {
  acceptInvitation,
  authenticationSlice,
  getUser,
  publicKey,
} from '../Slice/authenticationSlice';
import { commonSlice, userTemplateSlice } from '../Slice/commonSlice';
import { industry } from '../Slice/industrySlice';

const store = configureStore({
  reducer: {
    authentication: authenticationSlice.reducer,
    industry: industry.reducer,
    user: getUser.reducer,
    commonStateData: commonSlice.reducer,
    userTemplateData: userTemplateSlice.reducer,
    publicKey: publicKey.reducer,
    acceptInvitationState: acceptInvitation.reducer,
  },
});

export default store;
// Export the RootState type
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
