const RadioBlank = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="18" rx="9" fill="#FAFAFA" />
    <path
      d="M9 18C4.02943 18 0 13.9705 0 9C0 4.02943 4.02943 0 9 0C13.9705 0 18 4.02943 18 9C18 13.9705 13.9705 18 9 18ZM9 16.2C12.9765 16.2 16.2 12.9765 16.2 9C16.2 5.02355 12.9765 1.8 9 1.8C5.02355 1.8 1.8 5.02355 1.8 9C1.8 12.9765 5.02355 16.2 9 16.2Z"
      fill="#767680"
    />
  </svg>
);

export default RadioBlank;
