import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { ROUTE } from '../../Enum';
import HeaderLogo from '../../Assets/img/headerLogo';

const Header = () => (
  <Box
    className="header d-flex justify-content-between align-items-center"
    py={1.5}
  >
    <Box className="">
      <Link to={`/${ROUTE.LOGIN}`} className="logo-link">
        <HeaderLogo />
      </Link>
    </Box>
  </Box>
);

export default Header;
