import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { handleAsyncThunk } from '../../Utils/service';
import { UserAccountBody } from '../../Interface/User';
import { UserAccountTabType, UserRole } from '../../Enum';
import {
  InitialUpdateUserAccountState,
  InitialUserAccountState,
  InitialUserCountState,
} from '../Interface/userAccount';

export const addUser = createAsyncThunk(
  'addUser',
  async (data: UserAccountBody) =>
    handleAsyncThunk('/auth/users/user', 'post', data),
);

export const addBulkUser = createAsyncThunk(
  'addBulkUser',
  async (data: UserAccountBody[]) =>
    handleAsyncThunk('/auth/users/user/bulk', 'post', data),
);

export const getUserCountAPI = createAsyncThunk('getUserCount', async () =>
  handleAsyncThunk('/company/my?ignoreUserCount=true', 'get', ''),
);

export const getUserAccounts = createAsyncThunk(
  'getUserAccounts',
  async (data?: {
    role?: string;
    page?: number;
    pageSize?: number;
    order?: string;
    name: string;
    orderBy: string;
    status: UserAccountTabType;
  }) =>
    handleAsyncThunk(
      `/auth/users/users?status=${data.status}&roles=${
        data.role
          ? data.role
          : `${UserRole.TECHNICIAN},${UserRole.SERVICE_ADVISOR}`
      }${`&pageSize=${data?.pageSize}&page=${data?.page}${
        data?.order
          ? `&sortOrder=${data?.order}&orderBy=${
              data?.orderBy ? data?.orderBy : 'createdAt'
            }${data?.name ? `&search=${data?.name}` : ''}`
          : ''
      }`}`,
      'get',
      '',
    ),
);

export const getUserAccountByIdWithoutStore = async (id: string) =>
  handleAsyncThunk(`/auth/users/user/${id}`, 'get', '');

export const updateUser = createAsyncThunk(
  'updateUserAccounts',
  async (data: { values: any; id: string }) =>
    handleAsyncThunk(`/auth/users/user/${data.id}`, 'put', data.values),
);

export const deleteUserAccount = async (id: string) =>
  handleAsyncThunk(`/auth/users/user/${id}`, 'delete', '');

export const activeDeactivateUserAccount = async (data: {
  id: string;
  status: string;
}) =>
  handleAsyncThunk(`/auth/users/userStatus/${data.id}`, 'patch', {
    status: data.status,
  });

export const userAccountsList = createSlice({
  name: 'userAccountsList',
  initialState: {
    isLoading: false,
    userAccountsList: {},
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as InitialUserAccountState,
  extraReducers: (builder) => {
    // getTemplate
    builder.addCase(getUserAccounts.pending, (state) => {
      state.isLoading = true;
      state.error.message = '';
    });
    builder.addCase(getUserAccounts.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.userAccountsList = action?.payload?.response;
      } else {
        state.error.message = action?.payload?.response?.data?.message;
      }
    });
    builder.addCase(getUserAccounts.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  reducers: {
    clearUserAccountState(state: InitialUserAccountState) {
      state.userAccountsList = null;
      state.isLoading = false;
      state.error = {
        name: '',
        message: '',
        stack: '',
        code: '',
      };
    },
  },
});

export const updateUserAccount = createSlice({
  name: 'updateUserAccount',
  initialState: {
    isLoading: false,
    data: {},
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as InitialUpdateUserAccountState,
  extraReducers: (builder) => {
    // getTemplate
    builder.addCase(updateUser.pending, (state) => {
      state.isLoading = true;
      state.error.message = '';
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.data = action?.payload;
      } else {
        state.error.message = action?.payload?.response?.data?.message;
      }
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  reducers: {
    clearUpdateUserAccountState(state: InitialUpdateUserAccountState) {
      state.data = null;
      state.isLoading = false;
      state.error = {
        name: '',
        message: '',
        stack: '',
        code: '',
      };
    },
  },
});

export const addUserAccount = createSlice({
  name: 'addUserAccount',
  initialState: {
    isLoading: false,
    data: {},
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as InitialUpdateUserAccountState,
  extraReducers: (builder) => {
    // getTemplate
    builder.addCase(addUser.pending, (state) => {
      state.isLoading = true;
      state.error.message = '';
    });
    builder.addCase(addUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.data = action?.payload;
      } else {
        state.error.message = action?.payload?.response?.data?.message;
      }
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  reducers: {
    clearAddUserAccountState(state: InitialUpdateUserAccountState) {
      state.data = null;
      state.isLoading = false;
      state.error = {
        name: '',
        message: '',
        stack: '',
        code: '',
      };
    },
  },
});

export const addBulkUserAccount = createSlice({
  name: 'addBulkUserAccount',
  initialState: {
    isLoading: false,
    data: {},
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as InitialUpdateUserAccountState,
  extraReducers: (builder) => {
    // getTemplate
    builder.addCase(addBulkUser.pending, (state) => {
      state.isLoading = true;
      state.error.message = '';
    });
    builder.addCase(addBulkUser.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.data = action?.payload;
      } else {
        state.error.message = action?.payload?.response?.data?.message;
      }
    });
    builder.addCase(addBulkUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  reducers: {
    clearAddBulkUserAccountState(state: InitialUpdateUserAccountState) {
      state.data = null;
      state.isLoading = false;
      state.error = {
        name: '',
        message: '',
        stack: '',
        code: '',
      };
    },
  },
});

export const getUserCount = createSlice({
  name: 'getUserCount',
  initialState: {
    isLoading: false,
    data: {},
    error: {
      name: '',
      message: '',
      stack: '',
      code: '',
    },
  } as InitialUserCountState,
  extraReducers: (builder) => {
    // getTemplate
    builder.addCase(getUserCountAPI.pending, (state) => {
      state.isLoading = true;
      state.error.message = '';
    });
    builder.addCase(getUserCountAPI.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.status === 200) {
        state.data = action?.payload;
      } else {
        state.error.message = action?.payload?.response?.data?.message;
      }
    });
    builder.addCase(getUserCountAPI.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
  reducers: {
    clearGetUserCountState(state: InitialUserCountState) {
      state.data = null;
      state.isLoading = false;
      state.error = {
        name: '',
        message: '',
        stack: '',
        code: '',
      };
    },
  },
});

export const { clearUserAccountState } = userAccountsList.actions;
export const { clearUpdateUserAccountState } = updateUserAccount.actions;
export const { clearAddUserAccountState } = addUserAccount.actions;
export const { clearAddBulkUserAccountState } = addBulkUserAccount.actions;
export const { clearGetUserCountState } = getUserCount.actions;
