import {
  ApiOrder,
  TemplateOrderBy,
  UserAccountOrderBy,
  UserRole,
} from '../../../Enum';
import { userTemplate } from '../../../Redux/Slice/commonSlice';
import { AppDispatch } from '../../../Redux/Store/store';

export const checkEmailValidity = (emailId: string) => {
  if (emailId === undefined || (!emailId && emailId?.trim().length === 0)) {
    return 'Email address is required.';
  }
  if (emailId && !emailId.includes('@')) {
    return 'Email address must contain the ‘@’ symbol.';
  }
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      emailId.trim(),
    )
  ) {
    return undefined;
  }
  return 'Please enter valid email.';
};

export const checkRolevalidity = (role: string) => {
  if (role === undefined || (!role && role?.trim().length === 0)) {
    return 'Role is required.';
  }
  if (
    role &&
    (role.toUpperCase() === UserRole.TECHNICIAN ||
      role.toUpperCase() === UserRole.SERVICE_ADVISOR)
  ) {
    return undefined;
  }
  return 'Please enter valid role.';
};

export const checkPasswordValidity = (password: string) => {
  // Use the regex to check password validity
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{6,12}$/;

  // Check each criterion and return corresponding error messages
  if (!regex.test(password)) {
    return 'Password Must be 6-12 character with lowercase, uppercase, and 1 special character.';
  }

  // Password meets all criteria
  return null;
};
export const checkIfValueIsEmpty = (value: string) => {
  if (!value || value.trim().length === 0) {
    return true;
  }
  return null;
};

export const validateFirstAndLastName = (
  value: string,
  isFirstName: boolean,
) => {
  if (checkIfValueIsEmpty(value)) {
    return `${isFirstName ? 'First' : 'Last'} name is required.`;
  }
  if (/\s/.test(value.trim())) {
    return `Space not allowed in ${isFirstName ? 'first' : 'last'} name.`;
  }
  return null;
};

export const getRoleText = (role: string | undefined) => {
  let roleType = '';
  switch (role) {
    case UserRole.SERVICE_ADVISOR:
      roleType = 'Service adviser';
      break;
    case UserRole.TECHNICIAN:
      roleType = 'Technician';
      break;
    default:
      roleType = 'Admin';
      break;
  }
  return roleType;
};

export const resetOrder = (
  dispatch: AppDispatch,
  rowsPerPage: string,
  page: string,
  order: string,
  orderBy: string,
) => {
  dispatch(
    userTemplate({
      [rowsPerPage]: 10,
    }),
  );
  dispatch(
    userTemplate({
      [page]: 1,
    }),
  );
  dispatch(
    userTemplate({
      [order]: 'desc',
    }),
  );
  dispatch(
    userTemplate({
      [orderBy]: TemplateOrderBy.CREATED_AT,
    }),
  );
};

export const clearUserPagination = (dispatch) => {
  dispatch(
    userTemplate({
      userAccountPage: 1,
    }),
  );
  dispatch(
    userTemplate({
      userAccountRowsPerPage: 10,
    }),
  );
  dispatch(
    userTemplate({
      userAccountOrderBy: UserAccountOrderBy.EMAIL,
    }),
  );
  dispatch(
    userTemplate({
      userAccountOrder: ApiOrder.DESC,
    }),
  );
};

export const handleNameKeyDown = (event) => {
  if (
    !(
      event.key === 'Backspace' ||
      (event.key >= 'a' && event.key <= 'z') ||
      (event.key >= 'A' && event.key <= 'Z') ||
      event.key === 'Enter' ||
      event.key === 'Tab'
    )
  ) {
    event.preventDefault();
  }
};
