/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import commonInitialState, { userTemplateInitialState } from '../InitialState';
import { CommonStateDataInterface, UserTemplateData } from '../Interface';

export const commonSlice = createSlice({
  name: 'commonState',
  initialState: commonInitialState,
  reducers: {
    commonState(
      state: any,
      action: PayloadAction<{ type: string; data: object }>,
    ) {
      state[action.payload.type] = action.payload.data;
    },
    resetCommonState: (state: any) => {
      Object.keys(commonInitialState).forEach((key: any) => {
        state[key] = commonInitialState[key as keyof CommonStateDataInterface];
      });
    },
  },
});

export const { commonState, resetCommonState } = commonSlice.actions;

export const userTemplateSlice = createSlice({
  name: 'userTemplateSlice',
  initialState: userTemplateInitialState,
  reducers: {
    userTemplate(state: any, action: PayloadAction<object>) {
      state[Object.keys(action.payload)[0]] = Object.values(action.payload)[0];
    },
    resetUserTemplateState: (state: any) => {
      Object.keys(userTemplateInitialState).forEach((key: any) => {
        state[key] = userTemplateInitialState[key as keyof UserTemplateData];
      });
    },
  },
});

export const { userTemplate, resetUserTemplateState } =
  userTemplateSlice.actions;
